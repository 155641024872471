export const user = {
    LOGIN_STARTED: "login_started",
    LOGIN_SUCCESSFUL: "login_successful",
    LOGIN_FAILED: "login_failed",
    LOGOUT: 'logout'
  };

export const records = {
  FETCH_RECORDS_STARTED: 'fetch_records_started',
  FETCH_RECORDS_SUCCESSFUL: 'fetch_records_successful',
  FETCH_RECORDS_FAILED: 'fetch_records_failed',
  SET_SELECTED_RECORD_STARTED: 'set_selected_recorded_started',
  SET_SELECTED_RECORD_SUCCESSFUL: 'set_selected_recorded_successful',
  SET_SELECTED_RECORD_FAILED: 'set_selected_recorded_failed',
  FETCH_ATTACHMENT_STARTED: 'fetch_attachment_started',
  FETCH_ATTACHMENT_SUCCESSFUL: 'fetch_attachment_successful',
  FETCH_ATTACHMENT_FAILED: 'fetch_attachment_failed',
  UPDATE_SEACH_FIELDS_STARTED: 'update_search_field_started',
  UPDATE_SEACH_FIELDS_SUCCESSFUL: 'update_search_field_successful',
  UPDATE_SEACH_FIELDS_FAILED:  'update_search_field_failed',
}
