import axios from 'axios';

import {user} from '../constants/types'
import {API} from '../config'

export const loginUser = ({userName, userPass}) => {
    return dispatch => {

        dispatch({ type: user.LOGIN_STARTED });

        return axios
            .post(`${API.baseURL}/login`, 
                { userName, userPass }
            )
            .then(response => {
                dispatch({ type: user.LOGIN_SUCCESSFUL, payload:response.data });
            })
            .catch(error => {
                dispatch({ type: user.LOGIN_FAILED, payload: error.response });
            });
    };
};

export const logoutUser = () => {
    return dispatch => {
        localStorage.removeItem('user_LoggedIn');
        window.location = '/'
        dispatch({ type: user.LOGOUT });
        
    };
};

export default {
    loginUser
};