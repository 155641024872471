import React, { Component } from "react";
import { connect } from 'react-redux';
import {Container, Row, Col, Form, Button, Spinner} from "react-bootstrap";
import './login.scss'
import {loginUser} from '../../actions'
import logo from '../../assets/logo.png';

export class LoginPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      userPass: '',
      validated : false, 
      setValidated: false
    };
  }
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });

    const { target } = event;
    const { name, value } = target;

    console.log(name, value)

    this.setState({ [name]: value });
  }

  handleSubmit = (event) => {
    const {userName, userPass} = this.state;
    const { loginUser} = this.props

    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // }
    // this.setState({validated: true})

    // loginUser({userName, userPass})  
    if(userName==='Admin' && userPass === '123admin') {
      localStorage.setItem('user_LoggedIn', true)
      localStorage.setItem('role', 'Admin')
      localStorage.setItem('name', 'James Kayo')
      // this.props.history.push('/dashboard')
      window.location = '/home'
      // this.props.history.push('/dashboard')

    }

    if(userName==='CRO' && userPass === '123CRO') {
      localStorage.setItem('user_LoggedIn', true)
      localStorage.setItem('role', 'CRO Officer')
      localStorage.setItem('name', 'Peterson Atto')
      // this.props.history.push('/dashboard')
      window.location = '/home'
      // this.props.history.push('/dashboard')

    }

    if(userName==='SRO' && userPass === '123SRO') {
      localStorage.setItem('user_LoggedIn', true)
      localStorage.setItem('role', 'SRO Officer')
      localStorage.setItem('name', 'Kayowa John')
      this.props.history.push('/dashboard')
      // window.location = '/home'
      // this.props.history.push('/dashboard')
    }

    if(userName==='JO' && userPass === '123JO') {
      localStorage.setItem('user_LoggedIn', true)
      localStorage.setItem('role', 'Judicial Officer')
      localStorage.setItem('name', 'Petros Ayib')
      // this.props.history.push('/jlos')
      window.location = '/jlos'

    }
  }
  componentWillMount = () => {
    
    if(localStorage.getItem('user_LoggedIn')){
      if(localStorage.getItem('role')==='Judicial Officer') {
        this.props.history.push('/jlos')
      }else{
        this.props.history.push('/home')
      }

    }
    
  }
  render() {
    const { user : { isLoggingin, loggedIn} } = this.props
    const { validated } = this.state
    if(loggedIn) {
      localStorage.setItem('user_LoggedIn', true)
      // this.props.history.push('/home')
      window.location = '/home'
      // this.props.history.push('/dashboard')


    }
    return (
        <Container className="loginPage" fluid>
            <Row className="loginRow">
                <Col md={{ span: 4, offset: 4 }} className='loginSection' >
                  <img src={logo} alt='logo' className='logoImg'/>
                  <div class='deptLabels'>
                    <p>UGANDA POLICE FORCE</p>
                    {/* <small>DIRECTORATE OF FORENSIC SERVICES</small> */}
                  </div>
                  <Form noValidate validated={validated}>
                      <Form.Group controlId="formGroupEmail">
                        {/* <Form.Label>Username</Form.Label> */}
                        <Form.Control type="text" placeholder="Enter username" onChange={this.onChange} required name="userName"/>
                        <Form.Control.Feedback type="invalid">
                          Please your username
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="formGroupPassword">
                        {/* <Form.Label>Password</Form.Label> */}
                        <Form.Control type="password" placeholder="Enter password" onChange={this.onChange} required name="userPass"/>
                        <Form.Control.Feedback type="invalid">
                          Please your password
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Button variant="primary" onClick={this.handleSubmit} disabled={isLoggingin} block>
                        {
                          isLoggingin?<Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />:<></>
                        }
                        {isLoggingin?' Loading': 'Login'}

                      </Button>
                  </Form>
                        <p className='systemLabel'>eCRO System</p>
                </Col>
            </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ user }) => {
  
  return {
		user
	};
};

export default connect(
	mapStateToProps,
	{ loginUser }
)(LoginPage);