const yearCrimeChart = {
    // chart: {
    //     type: 'bar'
    // },

    title: {
        text: 'Major Case Trend, 2014-2020'
    },

    subtitle: {
        text: 'Source: CRM'
    },

    yAxis: {
        title: {
            text: 'Number of Cases'
        }
    },

    xAxis: {
        accessibility: {
            rangeDescription: 'Range: 2014 to 2020'
        }
    },

    legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle'
    },

    plotOptions: {
        series: {
            label: {
                connectorAllowed: true
            },
            pointStart: 2014
        }
    },

    series: [{
        name: 'Murder',
        data: [408, 400, 385, 398, 400, 386, 387, 381]
    }, {
        name: 'Aggrevated Murder',
        data: [341, 340, 345, 335, 340, 330, 333, 342]
    }, {
        name: 'Defilement',
        data: [314, 322, 315, 317, 318, 307, 327, 307]
    }, {
        name: 'Rape',
        data: [365, 350, 358, 369, 352, 362, 345, 347]
    }, {
        name: 'Treason',
        data: [339, 325, 320, 300, 310, 319, 327, 334]
    }],

    responsive: {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                legend: {
                    // layout: 'vertical',
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom'
                }
            }
        }]
    }

};

export default yearCrimeChart;