import React, { Component } from "react";
import { connect } from 'react-redux';
import {Container, Row, Col, Button,Modal, Table, Form, Tab, Tabs,ListGroup,ListGroupItem,Badge, ButtonToolbar} from "react-bootstrap";
import _ from 'lodash';

import Viewer from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import SpecificSearch from "../../components/specific-search/";
import logo from '../../assets/logo.png';
import DetailsBar from '../../components/details-bar'
import './judge.scss'
import {logoutUser, getUserRecords} from '../../actions'
import avatar from '../../assets/img/avatar.png'
import pdfFile from '../../assets/pdf/scan.pdf'
import criminalPic from '../../assets/img/profile.jpg'
import { StatsCard } from "../../components/StatsCard/StatsCard.jsx";


class JLOSPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setShow: false,
      showModal: false,
      showFingerModal: false,
      selected: {},
      showResults: false
    };
  }

  componentWillMount =()=>{
    const {
      props: {
        records: { 
          selectedRecord,
        },
        getRecordAttachment
      }
    } = this

    // if(!localStorage.getItem('user_LoggedIn') ){
    //   window.location = '/'
    // }

    this.props.getUserRecords({});

  }
  navigateTo = (screen) => {
    this.props.history.push(`/${screen}`)
  }
  handleClose = () => this.setState({showModal: false, showFingerModal:false});

  handleShow = () => {
    
    this.setState({showModal: true});
  }

  handleFingerprintShow = () => {
    this.setState({showFingerModal: true});
  }

  gotoDashboard = (show) => {
    localStorage.setItem('recordType', show);
    this.navigateTo('dashboard');
  }

  simulateSearch = () => {
    this.setState({showResults: true});
  }

  render() {
    const {
      props: {
        records: { 
          selectedRecord,
          data
        },
        logoutUser
      },
      state: {
        showResults
      }
    } = this

    const  {
      showModal,
      showFingerModal
    } = this.state

    const userData = data[0];

    const role = localStorage.getItem('role');

    return (
       <>
        <Container className="judgePage" fluid>
            <Row className="dashboard">
                <Col xs={12} md={3} className="sideBar">
                  <header>
                    {/* <h4>UGANDA POLICE</h4> */}
                    <div class="logo">
                      <a href="/admin/dashboard" className="simple-text logo-mini">
                        <div className="logo-img">
                          <img src={avatar} alt="logo_image" />
                        </div>
                      </a>
                      <a href="/admin/dashboard" className="simple-text logo-normal">
                        <p>{window.localStorage.getItem('name')}</p>
                        <small>{window.localStorage.getItem('role')}</small>
                      </a>
                    </div>
                    <div className='logout'>
                      <Button variant="secondary" size="sm" onClick={logoutUser}>
                        Logout
                    </Button>{' '}
                    </div>
                  </header>
                  <SpecificSearch />
                  <div className='actionBtn'>
                    <Button variant="warning" size="sm" onClick={() => this.simulateSearch()}>
                      Search
                    </Button> {' '}    
                    {/* <Button variant="warning" size="sm" onClick={() => this.navigateTo('home')}>
                      home
                    </Button> {' '}          */}
                  </div>
                </Col>
                <Col xs={12} md={9} className="mainSection">
                  <header>
                    <img src={logo} alt='logo'/>
                    {/* <h4 style={{marginBottom:'0'}}>Directorate of Forensics Services</h4> */}
                    <h4 style={{marginBottom:'0'}}>UGANDA POLICE FORCE</h4>
                    <label>eCRO System</label>
                  </header>
                  <main>
                    {showResults?<Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                      <Tab eventKey="home" title="Current Case">
                        <div className='caseTop'>
                          <Row>
                            <Col sm={4}>
                              <img src={criminalPic} alt='criminal profile pic' width='100%' />
                            </Col>
                            <Col sm={6} className='detailsContainer'>
                              <Row>
                                <Col sm={5}>Case File Number</Col>
                                <Col sm={7}><b>{userData.File_No}</b></Col>
                              </Row>
                              <Row>
                                <Col sm={5}>Name</Col>
                                <Col sm={7}><b>{userData._Name}</b></Col>
                              </Row>
                              <Row>
                                <Col sm={5}>Age</Col>
                                <Col sm={7}><b>{userData.Age}</b></Col>
                              </Row>
                              <Row>
                                <Col sm={5}>Color of Eye</Col>
                                <Col sm={7}><b>{userData.Eye}</b></Col>
                              </Row>
                              <Row>
                                <Col sm={5}>Color of Hair</Col>
                                <Col sm={7}><b>{userData.Hair}</b></Col>
                              </Row>
                              {/* <Row>
                                <Col sm={5}>Address at Time of Arrest</Col>
                                <Col sm={7}><b>{userData.LKAddress}</b></Col>
                              </Row> */}
                              {/* <Row>
                                <Col sm={5}>Names of Relatives</Col>
                                <Col sm={7}><b>{userData.Relative}</b></Col>
                              </Row> */}
                              {/* <Row>
                                <Col sm={5}>Present Residences of Parents</Col>
                                <Col sm={7}><b>{userData.ParentAddress}</b></Col>
                              </Row> */}
                              {/* <Row>
                                <Col sm={5}>District</Col>
                                <Col sm={7}><b>{userData.District}</b></Col>
                              </Row> */}
                              {/* <Row>
                                <Col sm={5}>Particulars of Employment</Col>
                                <Col sm={7}><b>{userData.Employement}</b></Col>
                              </Row> */}
                              <Row>
                                <Col sm={5}>Offense</Col>
                                <Col sm={7}><b>{!userData.offense?'Robbery':''}</b></Col>
                              </Row>
                            </Col>
                            </Row>
                            <Row className='criminalSummary'>
                              <Col sm={12}>
                              <h5 style={{textAlign:'left', width:'100%',fontSize:'1.0rem', background:'rgb(27 30 78)', padding:'6px', color:'#fff'}}>CRIMINAL SUMMARY</h5>
                              </Col>
                              <Col sm={6} >
                                <div style={{padding:'5px 0px', width:'100%', textAlign:'left'}}> <b>Previous Conviction</b></div>
                                <Row>
                                  <Col lg={6} sm={6}>
                                    <StatsCard
                                      link='/admin/get-licence'
                                      bigIcon={<i className="pe-7s-lock text-danger" />}
                                      statsText="Major"
                                      statsValue="02"
                                      statsIcon={<i className="fa fa-calendar-o" />}
                                      statsIconText="Major Cases"
                                      cover='red'
                                    />
                                  </Col>
                                  <Col lg={6} sm={6}>
                                    <StatsCard
                                      link='/admin/register'
                                      bigIcon={<i className="pe-7s-door-lock text-default" />}
                                      statsText="Minor"
                                      statsValue="01"
                                      statsIcon={<i className="fa fa-refresh" />}
                                      statsIconText="Minor Cases"
                                      cover='black'
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col sm={6}>
                                <div style={{padding:'5px 0px', width:'100%', textAlign:'left'}}><b>Ongoing Cases</b></div>
                                <Row>
                                  <Col lg={6} sm={6}>
                                    <StatsCard
                                      link='/admin/get-licence'
                                      bigIcon={<i className="pe-7s-lock text-danger" />}
                                      statsText="Major"
                                      statsValue="01"
                                      statsIcon={<i className="fa fa-calendar-o" />}
                                      statsIconText="Major Cases"
                                      cover='orange'
                                    />
                                  </Col>
                                  <Col lg={6} sm={6}>
                                    <StatsCard
                                      link='/admin/register'
                                      bigIcon={<i className="pe-7s-door-lock text-default" />}
                                      statsText="Minor"
                                      statsValue="01"
                                      statsIcon={<i className="fa fa-refresh" />}
                                      statsIconText="Minor Cases"
                                      cover='blue'
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            
                            </Row>
                        </div>
                      </Tab>
                      {role!=='SRO Officer'?<Tab eventKey="record" title="Previous Convictions (PF 45A)" >
                        <Table responsive size='sm'>
                          <thead>
                              <tr>
                              <th>Place of Trial</th>
                              <th>Date of Sentence</th>
                              <th>Sentence</th>
                              <th>Offence and Section</th>
                              <th>Name Convicted Under</th>
                              <th>Type</th>
                              </tr>
                          </thead>
                          <tbody hover='true'>
                              <tr>
                                <td>Nakawa</td>
                                <td>30/Nov/1990</td>
                                <td>Murder</td>
                                <td>Murder contrary to section xxxx of the penal code</td>
                                <td>John doe</td>
                                <td>Major</td>
                              </tr>
                              <tr>
                                <td>Buganda rd</td>
                                <td>01/Jan/2000</td>
                                <td>Man slaughter</td>
                                <td>Man slaughter contrary to section xxx of the penal code</td>
                                <td>John doe</td>
                                <td>Major</td>
                              </tr>
                              <tr>
                                <td>Buganda rd</td>
                                <td>05/May/2016</td>
                                <td>Man slaughter</td>
                                <td>Man slaughter contrary to section xxx of the penal code</td>
                                <td>John doe</td>
                                <td>Minor</td>
                              </tr>
                          </tbody>
                          </Table>
                      </Tab>:<></>}
                      <Tab eventKey="ongoing" title="Ongoing Cases" >
                        <Table responsive size='sm'>
                          <thead>
                              <tr>
                              <th>CRB Number</th>
                              <th>Date of Offence</th>
                              <th>Name on Charge Sheet</th>
                              <th>Case</th>
                              <th>Section of Law</th>
                              {/* <th>District</th> */}
                              <th>Type</th>
                              </tr>
                          </thead>
                          <tbody hover='true'>
                              <tr>
                                <td>CRB-000-123</td>
                                <td>30/Nov/1990</td>
                                <td>John doe</td>
                                <td>Robbery</td>
                                <td>Robbery contrary to section xxxx of the penal code</td>
                                {/* <td>view details</td> */}
                                <td>Major</td>
                              </tr>
                              <tr>
                                <td>CRB-000-124</td>
                                <td>01/Jan/2019</td>
                                <td>John doe</td>
                                <td>Man slaughter</td>
                                <td>Man slaughter contrary to section xxx of the penal code</td>
                                {/* <td>view details</td> */}
                                <td>Minor</td>
                              </tr>
                          </tbody>
                          </Table>
                      </Tab>
                    </Tabs>:<div className='no-results-box'>
                        <p>
                          Currently No Results
                          <small>Enter AFIS Number to show results</small>
                        </p>
                        
                    </div>}
                  </main>
                </Col>
            </Row>
        </Container>
        <Modal show={showModal} onHide={this.handleClose} size="md">
          <Modal.Header closeButton>
          <Modal.Title className='modalTitle'>Court results on present charge
          <br/><small>POLICE FORM F.P .45</small>
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="">
                  <Col className='loginSection' >
                  <Form>
                  <Form.Row>
                      <Form.Group as={Col} controlId="formGridCaseNo">
                        <Form.Label>Court</Form.Label>
                        <Form.Control type="text" placeholder="Enter court" />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Place of Trial</Form.Label>
                        <Form.Control type="text" placeholder='Place of trial'/>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridCaseNo">
                        <Form.Label>Court case no</Form.Label>
                        <Form.Control type="text" placeholder="Enter court case no" />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Date of Sentence</Form.Label>
                        <Form.Control type="date"/>
                      </Form.Group>
                    </Form.Row>

                    <Form.Group controlId="exampleForm.ControlTextareafupda1">
                      <Form.Label>Sentence</Form.Label>
                      <Form.Control as="textarea" rows="3" />
                    </Form.Group>

                    <Form.Group controlId="formGridAddress1">
                      <Form.Label>Offense <small>(quoting Law & section)</small></Form.Label>
                      <Form.Control as="textarea" rows="2" />
                    </Form.Group>

                    <Form.Group controlId="formGridAddress2">
                      <Form.Label>Remarks</Form.Label>
                      <Form.Control placeholder="Add remarks" />
                    </Form.Group>
                  </Form>
                  </Col>
              </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleRecordDetails} size="sm">
              Submit status
            </Button>      
          </Modal.Footer>
        </Modal>
        <Modal show={showFingerModal} onHide={this.handleClose} size="lg" className='pdfModal'>
          <Modal.Header closeButton>
          <Modal.Title className='modalTitle'>Fingerprint Attachment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="pdfDisplay">
                  <Col className='' >
                    {
                    // pdfRecord.data.length > 0?
                    //   <div
                    //       style={{
                    //           height: '750px',
                    //           width: '100%'
                    //       }}
                    //   >
                    //       <Viewer fileUrl={pdfRecord.data}/>
                    //   </div>
                    //   :<></>
                    }
                    {
                      pdfFile?
                      <Viewer fileUrl={pdfFile} />
                      :<></>
                    }
                  </Col>
              </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose}  size="sm">
              close
            </Button>      
          </Modal.Footer>
        </Modal>
        </>
    );
  }
}

const mapStateToProps = ({ user, records }) => {
	return {
    user,
    records
	};
};

export default connect(
	mapStateToProps,
	{ logoutUser, getUserRecords }
)(JLOSPage);