import React  from "react";
import { Row, Col} from "react-bootstrap";
import './detailsBar.scss'
import PropTypes from "prop-types";
import criminalPic from '../../assets/img/profile.jpg'

const DetailsBar = ({ data}) => {
    // Id: 780607,
    // File_No: 'IM-2073-2004',
    // Location: 'N6z0di05',
    // _Name: 'PHOEBA NAKIBUUKA ALEX',
    // DOB: '1954-04-19T21:15:00.000Z',
    // POB: 'NAMIREMBE',
    // Phone: ' ',
    // email: '',
    // NIN: '',
    // Notes: 'UGANDAN APPLIED FOR DEPENDANT\'S PASS FOR CHILD',
    // Photo: null,
    // fathers_name: '',
    // mothers_name: '',
    // no_of_passports: 1,
    // Nationality: 'Uganda',
    // Date_of_Birth: '1954-04-19T21:15:00.000Z',
    // Age: 66,
    // UserID: 85,
    // Company: 'N
    return (
        <Row className="detailsBar">
            <Col md={12} className='profilePic'>
                <img src={criminalPic} alt='criminal profile pic' />
            </Col>
            <Col md={12} >
                <header className='header main'>
                    <Row className='titleLabel'>
                        <Col sm={4}>CRB No:</Col>
                        <Col sm={8}><b>{data.File_No}</b></Col>
                    </Row>
                </header>
                <Row>
                    <Col sm={4} className='fieldItem'>CRO No</Col>
                    <Col sm={8} className='value name'><b>{data.CRO}</b></Col>
                </Row>
                <Row>
                    <Col sm={4} className='fieldItem'>AFIS No</Col>
                    <Col sm={8} className='value name'><b>{data.AFIS}</b></Col>
                </Row>
                <Row>
                    <Col sm={4} className='fieldItem'>Nationality</Col>
                    <Col sm={8} className='value'><b>{data.Nationality}</b></Col>
                </Row>
                {/* <Row>
                    <Col sm={4} className='fieldItem'>Passport No.</Col>
                    <Col sm={8} className='value'>{data.Nationality}</Col>
                </Row> */}
                <Row>
                    <Col sm={4} className='fieldItem'>National ID</Col>
                    <Col sm={8} className='value'><b>{data.NIN}</b></Col>
                </Row>
                <Row>
                    <Col sm={4} className='fieldItem'>Station</Col>
                    <Col sm={8} className='value'><b>{data.POB}</b></Col>
                </Row>
                {/* <header className='header document'>
                    <Row>
                        <Col sm={4}>Documents</Col>
                        <Col sm={8}></Col>
                    </Row>
                </header> */}
                {/* <Row>
                    <Col sm={12} className='value '>Fingerprint copy attached</Col>
                </Row> */}

            </Col>
        </Row>
    );
  }
  DetailsBar.defaultProps = {
    data: {
        Id: 780607,
        File_No: 'IM-0000-0000',
        Location: 'n7xxxxx',
        _Name: 'John Doe',
        DOB: '1954-04-19T21:15:00.000Z',
        POB: 'NAMIREMBE',
        Phone: ' ',
        email: '',
        NIN: '',
        Notes: '',
        Photo: null,
        fathers_name: '',
        mothers_name: '',
        no_of_passports: 1,
        Nationality: 'Uganda',
        Date_of_Birth: '',
        Age: 0,
        UserID: 0,
        Company: 'N',
        CRO: '00-00-00-00',
        AFIS: 'FF-UI-09-90'
    }
  };
  
  DetailsBar.propTypes = {
    data: PropTypes.object.isRequired,
  };
  
export default DetailsBar;