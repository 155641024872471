import React, { Component } from "react";
import Chart from "./chart";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import timelineOptions from "./timeChart";
import yearCrimeChart from "./yearCrimeChart";
import heatMapCrime from "./heatMapCrime";
import {Table} from "react-bootstrap";

class Dboard extends Component {
  render() {
    return (
      <>
        <div className="row">
          {this.props.charts &&
            this.props.charts.map((chart, i) => {
              return (
                <>
                  <div className="col-xs-12 col-sm-4 mb-2" key={i}>
                    <Chart
                      data={chart.serie}
                      userConfig={this.props.userConfig}
                      titleName={chart.title}
                    />
                  </div>
                </>
              );
            })}
        </div>
        <div class='row'>
          <div className="col-xs-12 col-sm-12 mb-2">
            <HighchartsReact highcharts={Highcharts} options={timelineOptions} />
          </div>

          <div className="col-xs-12 col-sm-12 mb-2">
            <HighchartsReact highcharts={Highcharts} options={yearCrimeChart} />
          </div>

        </div>
        <div className='row timeDashBoard'>
          <div className="col-xs-12 col-sm-12 mb-2">
            <p style={{paddingTop: '10px', fontWeight: 'bold', marginBottom: '2px'}}>Top 5 Cases Across Region</p>
            <Table striped bordered condensed hover className=''>
            <thead>
              <tr>
                <th>Region</th>
                <th>Murder</th>
                <th>Theft</th>
                <th>Rape</th>
                <th>Assault</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>KMP (N)</td>
                <td>02</td>
                <td>10</td>
                <td>23</td>
                <td>10</td>
              </tr>
              <tr>
                <td>KMP (S)</td>
                <td>100</td>
                <td>04</td>
                <td>09</td>
                <td>15</td>
              </tr>
              <tr>
                <td>Eastern Region</td>
                <td>15</td>
                <td>18</td>
                <td>17</td>
                <td>10</td>
              </tr>
              <tr>
                <td>Western Region</td>
                <td>12</td>
                <td>43</td>
                <td>56</td>
                <td>09</td>
              </tr>              
            </tbody>
          </Table>
          </div>
        </div>
      </>
    );
  }
}

export default Dboard;
