import axios from 'axios';
import _ from 'lodash';

import store from '../store'
import {records} from '../constants/types'
import {API, sampleData} from '../config'

export const getUserRecords = (data={}) => {
    return dispatch => {
        dispatch({ type: records.FETCH_RECORDS_STARTED });
        
        const searchFieldsObject = _.get(store.getState(), 'records.searchFields')
        const isWildSearch = _.get(searchFieldsObject, 'isWildSearch', true)
        const APIPATH = isWildSearch ? 'wild-search':'specific-search'
        const searchData = isWildSearch ?  searchFieldsObject :{
            searchSpecificBy: _.get(searchFieldsObject, 'specificFilterBy', true),
            searchSpecificValue: _.get(searchFieldsObject, 'specificFilterValue', true),
        }
        
        return axios
            .post(`${API.baseURL}/${APIPATH}`, searchData )
            .then(response => {
                dispatch({ type: records.FETCH_RECORDS_SUCCESSFUL, payload:sampleData[0]});

                // dispatch({ type: records.FETCH_RECORDS_SUCCESSFUL, payload:_.get(response, 'data') });
                return true
            })
            .catch(error => {
                console.log('>>>',sampleData)
                dispatch({ type: records.FETCH_RECORDS_SUCCESSFUL, payload:sampleData[0]});
                return true

                // dispatch({ type: records.FETCH_RECORDS_FAILED, payload: error });
                // return false
            });
    };
};

export const getSelectedRecord = (record = {}) => {
    return dispatch => {
        dispatch({ type: records.SET_SELECTED_RECORD_STARTED });
        
        if(record !== {} && record !== undefined){
            dispatch({ type: records.SET_SELECTED_RECORD_SUCCESSFUL, payload: record });
            return true        
        }
        else {
            dispatch({ type: records.SET_SELECTED_RECORD_STARTED_FAILED, payload: 'No record selected' });
            return false
        }
    };
}

export const getRecordAttachment = (recordID) => {
    return dispatch => {
        dispatch({ type: records.FETCH_ATTACHMENT_STARTED });

        return axios
            .post(`${API.baseURL}/get-attachment`, { recordID })
            .then(response => {
                
                dispatch({ type: records.FETCH_ATTACHMENT_SUCCESSFUL, payload:_.get(response, 'data') });
                return true
            })
            .catch(error => {
                dispatch({ type: records.FETCH_ATTACHMENT_FAILED, payload: error });
                return false
            });
    };
}

export const updateSearchField = (field = {name:'', value:''}, isWildSearch=true) => {
    return dispatch => {
        dispatch({ type: records.UPDATE_SEACH_FIELDS_STARTED });
        
        if(field !== {} && field !== undefined){
            const currentField = store.getState().records.searchFields
            currentField[field.name] = field.value
            currentField['isWildSearch'] = isWildSearch;
            
            dispatch({ type: records.UPDATE_SEACH_FIELDS_SUCCESSFUL, payload: currentField });
            return true        
        }
        else {
            dispatch({ type: records.UPDATE_SEACH_FIELDS_FAILED, payload: 'No record selected' });
            return false
        }
    };
}

export default {
    getUserRecords,
    getSelectedRecord
};