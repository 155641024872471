import React, { Component } from "react";
import { connect } from 'react-redux';
import {Container, Row, Col, Button, Modal, Spinner,ListGroup,ListGroupItem,Badge} from "react-bootstrap";
import Page from 'react-page-loading'
import Moment from 'moment';
import _ from 'lodash';

import SpecificSearch from "../../components/specific-search/";
import WildcardSearch from "../../components/wildcard-seach";
import Table from 'react-bootstrap/Table'
import logo from '../../assets/logo.png';
import avatar from '../../assets/img/avatar.png'

import './dashboard.scss'
import {getUserRecords, getSelectedRecord, updateSearchField, logoutUser} from '../../actions'
import {permanentData} from '../../config'


class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setShow: false,
      showModal: false,
      selected: {}
    };
  }

  componentWillMount =()=>{
    
    if(!localStorage.getItem('user_LoggedIn') ){
      window.location = '/'
    }

    this.props.getUserRecords({});
  }

  formatDate = (date) => {
    Moment.locale('en');
    return Moment(date).format('d-MMM-YYYY');
  }

  handleClose = () => this.setState({showModal: false});

  handleShow = (recordId, isPermanent=false) => {
    let {records: { data }} = this.props;
    if(isPermanent) {
      data = permanentData[0]
    }
    var dt = data.filter((record) => record.Id === recordId);
    
    this.setState({selected: dt[0], showModal: true});
  }

  handleRecordDetails = () => {
    const { selected } = this.state;

    if(this.props.getSelectedRecord(selected)) {
      // window.location = '/results'
      this.props.history.push('/results')
    }

  }

  navigateTo = (screen) => {
    this.props.history.push(`/${screen}`)
  }

  handleSearchResults = () => {
    const { 
      records: {
        searchFields
      }
    } = this.props

    const searchType  = _.get(searchFields, 'isWildSearch', false) === true?'wild':'specific';
    console.log(searchType, _.get(searchFields, 'isWildSearch', false))

    this.props.getUserRecords({});

  }
  gotoDashboard = (show) => {
    localStorage.setItem('recordType', show);
    this.navigateTo('dashboard');
  }

  render() {
    const {
      records: { data, isFetchingRecords },
      logoutUser
    } = this.props

    const  {
      showModal,
      selected
    } = this.state
    const isLoggedIn = localStorage.getItem('user_LoggedIn');
    const role = localStorage.getItem('role');
    const recordType = localStorage.getItem('recordType');

    return (
      <div>
      <Container className="dashboardPage" fluid>
        <Page loader={"bar"} color={"#A9A9A9"} size={6}>
        </Page>
          { isLoggedIn?
              <Row className="dashboard">
                    <Col xs={12} md={3} className="sideBar">
                      <header>
                        {/* <h4>UGANDA POLICE</h4> */}
                        <div className="logo">
                          <a href="/admin/dashboard" className="simple-text logo-mini">
                            <div className="logo-img">
                              <img src={avatar} alt="logo_image" />
                            </div>
                          </a>
                          <a href="/admin/dashboard" className="simple-text logo-normal">
                            <p>{window.localStorage.getItem('name')}</p>
                            <small>{window.localStorage.getItem('role')}</small>
                          </a>
                        </div>
                        <div className='logout'>
                          <Button variant="secondary" size="sm"  onClick={logoutUser}>
                            Logout
                          </Button>{' '}
                        </div>
                      </header>
                      <ListGroup style={{textAlign:'left', marginBottom:'10px'}}>
                          {
                            role!=='SRO Officer'?<ListGroupItem onClick={()=>this.navigateTo('home')}>Dashboard</ListGroupItem>:<></>
                          }
                          {
                            role==='SRO Officer'?<ListGroupItem >CASE Requests<Badge>102</Badge></ListGroupItem>:<></>
                          }
                          {
                            role==='CRO Officer'?<ListGroupItem onClick={()=>this.gotoDashboard('temp')}>Temporary Records <Badge>5200</Badge></ListGroupItem>:<></>
                          }
                          {
                            role!=='SRO Officer'?<ListGroupItem onClick={()=>this.gotoDashboard('perm')}>Permanent Records <Badge>300</Badge></ListGroupItem>:<></>
                          }
                        </ListGroup>
                      
                      <SpecificSearch /><div style={{marginBottom:'4px'}} />
                      <WildcardSearch />
                      <div className='actionBtn'>
                        {/* <Button variant="secondary" size="sm" onClick={logoutUser}>
                            Logout
                        </Button>{' '} */}
                        <Button variant="warning" size="sm">
                          clear
                        </Button> {' '}       
                        <Button variant="primary" size="sm" onClick={this.handleSearchResults} disabled={isFetchingRecords}>
                        {
                          isFetchingRecords?<Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />:<></>
                        }
                        {isFetchingRecords?' loading...': 'Search'}

                      </Button>
                    </div>
                    </Col>
                    <Col xs={12} md={9} className="mainSection">
                      <header>
                        <img src={logo} alt='logo'/>
                        <h4 style={{marginBottom:'0'}}>UGANDA POLICE FORCE</h4>
                        {/* <h4 style={{marginBottom:'0'}}>Directorate of Forensic Services</h4> */}
                        <label>eCRO System</label>
                      </header>
                      <main>
                      {role==='SRO Officer' || recordType==='temp'?
                      <>
                      <p style={{paddingTop:'10px',marginBottom:'5px'}}><b>Temporary Records</b></p>
                      <Table responsive size='sm'>
                        <thead>
                            <tr>
                            <th>CRB Number</th>
                            <th>Officer</th>
                            <th>Suspect Name</th>
                            <th>Date of Birth</th>
                            <th>Station</th>
                            <th>NIN</th>
                            <th>Nationality</th>
                            {/* <th>Age</th> */}
                            <th>District</th>
                            </tr>
                        </thead>
                        <tbody hover='true'>
                            {(!isFetchingRecords && data.length > 0) ? data.map(record => (
                              <tr key={record.Id} onClick={() => this.handleShow(record.Id)} >
                                <td>{record.File_No}</td>
                                <td>{record.Location}</td>
                                <td>{record._Name}</td>
                                <td>{this.formatDate(record.DOB)}</td>
                                <td>{record.POB}</td>
                                <td>{record.NIN}</td>
                                <td>{record.Nationality}</td>
                                {/* <td>{record.Age}</td> */}
                                <td>{record.Company}</td>
                              </tr>
                            )): <tr><td className='noResults'>{'No results returned'}</td></tr> }
                        </tbody>
                        </Table>
                        </>
                        :
                        <>
                        <p style={{paddingTop:'10px',marginBottom:'5px'}}><b>Permanent Records</b></p>
                        <Table responsive size='sm'>
                        <thead>
                            <tr>
                            <th>CR0 Number</th>
                            <th>Name Convicted</th>
                            <th>Place of Trial</th>
                            <th>Date of Sentence</th>
                            <th>Sentence</th>
                            <th>Offence & Section</th>
                            </tr>
                        </thead>
                        <tbody hover='true'>
                            {(!isFetchingRecords && permanentData[0].length > 0) ? permanentData[0].map(record => (
                              <tr key={record.Id} onClick={() => this.handleShow(record.Id, true)} >
                                <td>{record.CRO_No}</td>
                                <td>{record._Name}</td>
                                <td>{record.PlaceOfTrial}</td>
                                <td>{this.formatDate(record.DOB)}</td>
                                <td>{record.Sentence}</td>
                                <td>{record.Offense}</td>
                              </tr>
                            )): <tr><td className='noResults'>{'No results returned'}</td></tr> }
                        </tbody>
                        </Table>
                        </>
                        }
                      </main>
                    </Col>
                </Row>:
              <div></div>
          }
      </Container>
      <Modal show={showModal} onHide={this.handleClose} size="md">
        <Modal.Header closeButton>
        <Modal.Title className='modalTitle'>{selected._Name} <small>{'case file'}</small></Modal.Title>
        </Modal.Header>
        <Modal.Body>Click on <b>Open details</b> to view the case details as well as update its status </Modal.Body>
        <Modal.Footer>
          <Button variant="warning"  size="sm">
            Request Case Status (PF45)
          </Button>
          <Button variant="primary" onClick={this.handleRecordDetails} size="sm">
            Open Case Details
          </Button>    
          <Button variant="secondary" onClick={this.handleClose} size="sm">
            Close
          </Button>      
        </Modal.Footer>
      </Modal>
      </div>
    )
  }
}


const mapStateToProps = ({ user, records }) => {
	return {
    user,
    records
	};
};

export default connect(
	mapStateToProps,
	{ getUserRecords, getSelectedRecord, updateSearchField, logoutUser }
)(DashboardPage);