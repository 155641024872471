const timelineOptions = {
    chart: {
        type: 'line'
    },
    title: {
        text: 'Case Trend over Months'
    },
    subtitle: {
        text: '......'
    },
    xAxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    },
    yAxis: {
        title: {
            text: 'Case Count ( ,00)'
        }
    },
    plotOptions: {
        line: {
            dataLabels: {
                enabled: true
            },
            enableMouseTracking: false
        }
    },
    series: [{
        name: 'Murder',
        data: [7, 9, 5, 14, 24, 25, 12, 25, 23, 18, 19, 9]
    }, {
        name: 'Man Slaughter',
        data: [9, 4, 7, 8, 11, 20, 17, 36, 32, 30, 16, 4]
    },
    {
        name: 'Theft',
        data: [5.9, 14, 27, 18, 11, 25, 17, 16, 14, 10, 16, 8]
    }]
  };

export default timelineOptions;