import { user } from '../constants/types'

const INITIAL_STATE = {
	data: {	},
    isLoggingin: false,
	loggedIn: false,
	error: ''
};

export const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case user.LOGOUT:

			return { ...INITIAL_STATE };

		case user.LOGIN_STARTED:
			return { ...state, isLoggingin:true };

		case user.LOGIN_SUCCESSFUL:
			return { ...state, data: action.payload , isLoggingin:true, loggedIn:true };

		case user.LOGIN_FAILED:
			return { ...state, error: action.payload, isLoggingin:false, loggedIn: false  };

		default:
			return state;
	}
};
