import React from 'react';
import { Provider } from 'react-redux'
import { Link, Route, Switch } from "react-router-dom";
// import logo from './logo.svg';
import './App.scss';
import LoginPage from './layout/login/';
import DashboardPage from './layout/dashboard/'
import ResultsPage from './layout/results/'
import HomePage from './layout/home/'
import JLOSPage from './layout/judge/'
import store from './store'
import { Worker } from '@phuocng/react-pdf-viewer';

function App() {
  return (
    <Provider store={store}>
      <Worker
            workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js"
        >
        <div className="App">
          <Route path="/" component={LoginPage} />
          <Route path="/dashboard" component={DashboardPage} />
          <Route path="/results" component={ResultsPage} />
          <Route path="/home" component={HomePage} />
          <Route path="/jlos" component={JLOSPage} />
        </div>
      </Worker>
    </Provider>
  );
}

export default App;
