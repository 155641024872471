import { records } from '../constants/types'
import {seachFieldSchema} from '../config';

const INITIAL_STATE = {
	data: [],
    isFetchingRecords: false,
    isFetchingAttachment: false,
    isUpdatingField: false,
	error: '',
	selectedRecord: {},
	selectedRecordSet: false,
	attachmentFetched: false,
	fieldUpdated: false,
	searchFields: seachFieldSchema,
};

export const recordsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {

		case records.FETCH_RECORDS_STARTED:
			return { ...state, isFetchingRecords:true };

		case records.FETCH_RECORDS_SUCCESSFUL:
			return { ...state, data: action.payload , isFetchingRecords:false};

		case records.FETCH_RECORDS_FAILED:
			return { ...state, error: action.payload, isFetchingRecords:false};

		case records.SET_SELECTED_RECORD_STARTED:
			return { ...state,};

		case records.SET_SELECTED_RECORD_SUCCESSFUL:
			return { ...state, selectedRecord: action.payload, selectedRecordSet: true};

		case records.SET_SELECTED_RECORD_FAILED:
			return { ...state, error: action.payload, selectedRecordSet: false};

		case records.FETCH_ATTACHMENT_STARTED:
			return { ...state,isFetchingAttachment: true};

		case records.FETCH_ATTACHMENT_SUCCESSFUL:
			return { ...state, attachment: action.payload, attachmentFetched: true, isFetchingAttachment: false};

		case records.FETCH_ATTACHMENT_FAILED:
			return { ...state, error: action.payload, attachmentFetched: false, isFetchingAttachment: false};

		case records.UPDATE_SEACH_FIELDS_STARTED:
			return { ...state,isUpdatingField: true};

		case records.UPDATE_SEACH_FIELDS_SUCCESSFUL:
			return { ...state, searchFields:action.payload, fieldUpdated: true, isUpdatingField: false};

		case records.UPDATE_SEACH_FIELDS_FAILED:
			return { ...state, error: action.payload, fieldUpdated: false, isUpdatingField: false};
			
		default:
			return state;
	}
};
