
import {
    createStore, applyMiddleware, compose, combineReducers,
  } from 'redux';
import {userReducer, recordsReducer} from "../reducers/";

import thunk from 'redux-thunk';

const reducers = combineReducers({
    user: userReducer,
    records: recordsReducer
});
  

const enhancers = compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : f => f,
  );
const store = createStore(
    reducers, enhancers,
);

export default store;