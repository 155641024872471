import React, { Component } from "react";
import { connect } from 'react-redux';
import {Container, Row, Col, Button,Modal, Table, Form, Tab, Tabs,ListGroup,ListGroupItem,Badge} from "react-bootstrap";
import _ from 'lodash';

import Viewer from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

import logo from '../../assets/logo.png';
import DetailsBar from '../../components/details-bar/'
import './results.scss'
import {logoutUser, getRecordAttachment} from '../../actions'
import avatar from '../../assets/img/avatar.png'
import pdfFile from '../../assets/pdf/scan.pdf'

class ResultsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setShow: false,
      showModal: false,
      showFingerModal: false,
      selected: {}
    };
  }

  componentWillMount =()=>{
    const {
      props: {
        records: { 
          selectedRecord,
        },
        getRecordAttachment
      }
    } = this

    // if(!localStorage.getItem('user_LoggedIn') ){
    //   window.location = '/'
    // }

    getRecordAttachment(selectedRecord.Id);

  }
  navigateTo = (screen) => {
    this.props.history.push(`/${screen}`)
  }
  handleClose = () => this.setState({showModal: false, showFingerModal:false});

  handleShow = () => {
    
    this.setState({showModal: true});
  }

  handleFingerprintShow = () => {
    this.setState({showFingerModal: true});
  }

  gotoDashboard = (show) => {
    localStorage.setItem('recordType', show);
    this.navigateTo('dashboard');
  }

  render() {
    const {
      props: {
        records: { 
          selectedRecord,
          attachment
        },
        logoutUser
      }
    } = this

    const  {
      showModal,
      showFingerModal
    } = this.state

    // console.log(attachment.data[0].File_data)
    const attachedDoc = _.get(attachment, 'data', []);

    let pdfRecord = {
      data: []
    }
    if(attachedDoc.length > 0 ) {
      const fileData = _.get(attachedDoc[0], 'File_data', {})
      const fileBuffer = _.get(fileData, 'data', [])
      
      const uint8Array  = new Uint8Array(fileBuffer);

      pdfRecord = {
        data: uint8Array
      }
      
    }
    const role = localStorage.getItem('role');

     return (
       <>
        <Container className="resultsPage" fluid>
            <Row className="dashboard">
                <Col xs={12} md={3} className="sideBar">
                  <header>
                    {/* <h4>UGANDA POLICE</h4> */}
                    <div class="logo">
                      <a href="/admin/dashboard" className="simple-text logo-mini">
                        <div className="logo-img">
                          <img src={avatar} alt="logo_image" />
                        </div>
                      </a>
                      <a href="/admin/dashboard" className="simple-text logo-normal">
                        <p>{window.localStorage.getItem('name')}</p>
                        <small>{window.localStorage.getItem('role')}</small>
                      </a>
                    </div>
                    <div className='logout'>
                      <Button variant="secondary" size="sm" onClick={logoutUser}>
                        Logout
                    </Button>{' '}
                    </div>
                  </header>
                  <ListGroup style={{textAlign:'left', marginBottom:'10px'}}>
                          {
                            role!=='SRO Officer'?<ListGroupItem onClick={()=>this.navigateTo('home')}>Back to Dashboard</ListGroupItem>:<></>
                          }
                          {
                            role==='SRO Officer'?<ListGroupItem >Update Request<Badge>102</Badge></ListGroupItem>:<></>
                          }
                          {
                            role!=='SRO Officer'?<ListGroupItem onClick={()=>this.gotoDashboard('perm')}>Permanent Records <Badge>300</Badge></ListGroupItem>:<></>
                          }
                    </ListGroup>

                  { selectedRecord !== {} ? 
                      <DetailsBar data={selectedRecord}/>:
                      <div></div>
                    }
                  <div className='actionBtn'>
                    <Button variant="warning" size="sm" onClick={() => this.navigateTo('dashboard')}>
                      Back
                    </Button> {' '}    
                    {/* <Button variant="warning" size="sm" onClick={() => this.navigateTo('home')}>
                      home
                    </Button> {' '}          */}
                  </div>
                </Col>
                <Col xs={12} md={9} className="mainSection">
                  <header>
                    <img src={logo} alt='logo'/>
                    {/* <h4 style={{marginBottom:'0'}}>Directorate of Forensics Services</h4> */}
                    <h4 style={{marginBottom:'0'}}>UGANDA POLICE FORCE</h4>
                    <label>eCRO System</label>
                  </header>
                  <main>
                    <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                      <Tab eventKey="home" title="Case Details">
                        <div className='caseTop'>
                          <Row>
                            <Col sm={8} className='detailsContainer'>
                              <b>{}</b>
                              <Row>
                                <Col sm={4}>Case File Number</Col>
                                <Col sm={8}><b>{selectedRecord.File_No}</b></Col>
                              </Row>
                              <Row>
                                <Col sm={4}>Name</Col>
                                <Col sm={8}><b>{selectedRecord._Name}</b></Col>
                              </Row>
                              <Row>
                                <Col sm={4}>Age</Col>
                                <Col sm={8}><b>{selectedRecord.Age}</b></Col>
                              </Row>
                              <Row>
                                <Col sm={4}>Color of Eye</Col>
                                <Col sm={8}><b>{selectedRecord.Eye}</b></Col>
                              </Row>
                              <Row>
                                <Col sm={4}>Color of Hair</Col>
                                <Col sm={8}><b>{selectedRecord.Hair}</b></Col>
                              </Row>
                              <Row>
                                <Col sm={4}>Address at Time of Arrest</Col>
                                <Col sm={8}><b>{selectedRecord.LKAddress}</b></Col>
                              </Row>
                              <Row>
                                <Col sm={4}>Names of Relatives</Col>
                                <Col sm={8}><b>{selectedRecord.Relative}</b></Col>
                              </Row>
                              <Row>
                                <Col sm={4}>Present Residences of Parents</Col>
                                <Col sm={8}><b>{selectedRecord.ParentAddress}</b></Col>
                              </Row>
                              <Row>
                                <Col sm={4}>District</Col>
                                <Col sm={8}><b>{selectedRecord.District}</b></Col>
                              </Row>
                              <Row>
                                <Col sm={4}>Particulars of Employment</Col>
                                <Col sm={8}><b>{selectedRecord.Employement}</b></Col>
                              </Row>
                              <Row>
                                <Col sm={4}>Offense</Col>
                                <Col sm={8}><b>{selectedRecord.offense}</b></Col>
                              </Row>
                            </Col>
                            <Col sm={4}>

                            </Col>
                          </Row>
                          <div className='actionButton'>
                            <Row style={{marginLeft:'-18px'}}>
                              <Col sm={2}>
                                <Button variant="primary" size="sm" onClick={this.handleShow}>
                                  Update Case Results (PF.45)
                                </Button>
                              </Col>
                              <Col sm={2}>
                                <Button variant="primary" onClick={this.handleFingerprintShow} size="sm">
                                  View Fingerprint Details
                                </Button>
                              </Col>
                              <Col sm={2}>
                                <Button variant="primary"  size="sm">
                                  Post Charges Sheet (PF.53)
                                </Button>
                              </Col>
                              <Col sm={2}>
                                <Button variant="primary" size="sm">
                                  Post Antecedents (PF.51a)
                                </Button>
                              </Col>
                              <Col sm={2}>
                                <Button variant="primary"  size="sm">
                                  Post Modus Operandi (PF.49)
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Tab>
                      {role!=='SRO Officer'?<Tab eventKey="record" title="Previous Records (FP 45A)">
                        <Table responsive size='sm'>
                          <thead>
                              <tr>
                              <th>Place of Trial</th>
                              <th>Date of Sentence</th>
                              <th>Sentence</th>
                              <th>Offence and Section</th>
                              <th>Name Convicted Under</th>
                              </tr>
                          </thead>
                          <tbody hover='true'>
                              <tr>
                                <td>Nakawa</td>
                                <td>30/Nov/1990</td>
                                <td>Murder</td>
                                <td>Murder contrary to section xxxx of the penal code</td>
                                <td>John doe</td>
                                <td>view details</td>
                              </tr>
                              <tr>
                                <td>Buganda rd</td>
                                <td>01/Jan/2019</td>
                                <td>Man slaughter</td>
                                <td>Man slaughter contrary to section xxx of the penal code</td>
                                <td>John doe</td>
                                <td>view details</td>
                              </tr>
                          </tbody>
                          </Table>
                      </Tab>:<></>}
                    </Tabs>
                  </main>
                </Col>
            </Row>
        </Container>
        <Modal show={showModal} onHide={this.handleClose} size="md">
          <Modal.Header closeButton>
          <Modal.Title className='modalTitle'>Court results on present charge
          <br/><small>POLICE FORM F.P .45</small>
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="">
                  <Col className='loginSection' >
                  <Form>
                  <Form.Row>
                      <Form.Group as={Col} controlId="formGridCaseNo">
                        <Form.Label>Court</Form.Label>
                        <Form.Control type="text" placeholder="Enter court" />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Place of Trial</Form.Label>
                        <Form.Control type="text" placeholder='Place of trial'/>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridCaseNo">
                        <Form.Label>Court case no</Form.Label>
                        <Form.Control type="text" placeholder="Enter court case no" />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Date of Sentence</Form.Label>
                        <Form.Control type="date"/>
                      </Form.Group>
                    </Form.Row>

                    <Form.Group controlId="exampleForm.ControlTextareafupda1">
                      <Form.Label>Sentence</Form.Label>
                      <Form.Control as="textarea" rows="3" />
                    </Form.Group>

                    <Form.Group controlId="formGridAddress1">
                      <Form.Label>Offense <small>(quoting Law & section)</small></Form.Label>
                      <Form.Control as="textarea" rows="2" />
                    </Form.Group>

                    <Form.Group controlId="formGridAddress2">
                      <Form.Label>Remarks</Form.Label>
                      <Form.Control placeholder="Add remarks" />
                    </Form.Group>
                  </Form>
                  </Col>
              </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleRecordDetails} size="sm">
              Submit status
            </Button>      
          </Modal.Footer>
        </Modal>
        <Modal show={showFingerModal} onHide={this.handleClose} size="lg" className='pdfModal'>
          <Modal.Header closeButton>
          <Modal.Title className='modalTitle'>Fingerprint Attachment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="pdfDisplay">
                  <Col className='' >
                    {
                    // pdfRecord.data.length > 0?
                    //   <div
                    //       style={{
                    //           height: '750px',
                    //           width: '100%'
                    //       }}
                    //   >
                    //       <Viewer fileUrl={pdfRecord.data}/>
                    //   </div>
                    //   :<></>
                    }
                    {
                      pdfFile?
                      <Viewer fileUrl={pdfFile} />
                      :<></>
                    }
                  </Col>
              </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose}  size="sm">
              close
            </Button>      
          </Modal.Footer>
        </Modal>
        </>
    );
  }
}

const mapStateToProps = ({ user, records }) => {
	return {
    user,
    records
	};
};

export default connect(
	mapStateToProps,
	{ logoutUser, getRecordAttachment }
)(ResultsPage);