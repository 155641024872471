import React from "react";
import data from "./data";
let fossilFuelData = [],
  minorCasesData = [],
  renewableEnergyData = [],
  biomassData = [];

let dataProcessing = (yearFrom, yearTo) => {
  let msg;
  if (yearFrom <= yearTo) {
    let capitalOffence = 0,
      minorCases = 0,
      pcoke = 0,
      ngas = 0,
      ogas = 0,
      nuclear = 0,
      chydroelectric = 0,
      wind = 0,
      solar = 0,
      geothermal = 0,
      biomass = 0,
      wood = 0,
      otherbio = 0,
      terrorism=0;
    for (let i = yearFrom; i - 1 < yearTo; i++) {
      capitalOffence += data.capitalOffence[i];
      minorCases += data.minorCases[i];
      pcoke += data.pcoke[i];
      ngas += data.ngas[i];
      ogas += data.ogas[i];
      terrorism+=data.terrorism[i];
      nuclear += data.nuclear[i];
      chydroelectric += data.chydroelectric[i];
      wind += data.wind[i];
      solar += data.solar[i];
      geothermal += data.geothermal[i];
      biomass += data.biomass[i];
      wood += data.wood[i];
      otherbio += data.otherbio[i];
    }

    fossilFuelData = [
      { name: "Murder", y: capitalOffence },
      { name: "Aggrevated Robbery", y: minorCases },
      { name: "Defilement", y: pcoke },
      { name: "Rape", y: ngas },
      { name: "Treason", y: ogas },
      { name: "Terrorism", y: terrorism }
    ];

    minorCasesData = [
      { name: "Affray", y: ogas },
      { name: "Voilence", y: nuclear },
      { name: "Assault", y: ngas/2 },
      { name: "Public Nuisance", y: minorCases },
      { name: "Ob. Money by False Pretence", y: pcoke },
      { name: "Theft", y: terrorism/1.2 },
    ];

    biomassData = [
      { name: "Biomass", y: biomass },
      { name: "Wood", y: wood },
      { name: "Otherbio", y: otherbio }
    ];

    renewableEnergyData = [
      { name: "Bond", y: wind/1.8 },
      { name: "Reconciliation", y: solar/14 },
      { name: "Screened Out", y: geothermal/1.5 },
      { name: "Pending further Devs", y: geothermal/2 },
    ];
    msg = "Select the range";
  } else {
    msg = (
      <>
        Year <b>From</b> should be less or equal to year <b>To</b>
      </>
    ); //do nothing
  }
  return msg;
};

export default dataProcessing;
export { fossilFuelData, minorCasesData, biomassData, renewableEnergyData };
