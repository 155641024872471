import React, { Component } from "react";
import { connect } from 'react-redux';
import {Container, Row, Col, Button, Modal,Panel, Spinner, ButtonToolbar, ToggleButtonGroup,ToggleButton,Table,Badge,ListGroup,ListGroupItem, Form, FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import Page from 'react-page-loading'
import Moment from 'moment';
import _ from 'lodash';

import logo from '../../assets/logo.png';
import avatar from '../../assets/img/avatar.png'

import './home.scss'
import {getUserRecords, getSelectedRecord, updateSearchField, logoutUser} from '../../actions'

import template from "../../constants/template";
import Selection from "../../constants/selection";
import Dboard from "../../constants/dboard";
import WildcardSearch from "../../components/wildcard-seach/";

import dataProcessing, {
  fossilFuelData,
  minorCasesData,
  renewableEnergyData,
  biomassData
} from "../../constants/dataProcessing";

class HomePage extends Component {
  constructor(props) {
    super(props);

    // this.state = {
    //   setShow: false,
    //   showModal: false,
    //   selected: {}
    // };

  }


  state = template;

  copyDataSeries = (obj = {}) => {
    this.setState({
      ...obj,

      charts: [
        { serie: fossilFuelData, title: "Capital Offense Statistics" },
        { serie: minorCasesData, title: "Minor Offense Statistics" },
        { serie: renewableEnergyData, title: "Put Away Cases" },
        // { serie: biomassData, title: "Renewable Energy" }
      ]
    });
  };
  
  componentDidMount() {
    dataProcessing(this.state.yearFrom, this.state.yearTo, this.state.msg);
    this.copyDataSeries();

    const role = localStorage.getItem('role');
    if(role ==='SRO Officer') {
      this.props.history.push('/dashboard')
    }
  }

  handleSubmit = e => {
    let msg = dataProcessing(this.state.yearFrom, this.state.yearTo);
    this.copyDataSeries({ msg: msg });
    e.preventDefault();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.yearFrom !== this.state.yearFrom) {
      this.handleChangeSelect();
    }
    if (prevState.yearTo !== this.state.yearTo) {
      this.handleChangeSelect();
    }
  }
  handleChangeSelect() {
    let msg = dataProcessing(this.state.yearFrom, this.state.yearTo);
    this.copyDataSeries({ msg: msg });
  }

  handleChangeYear = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  navigateTo = (screen) => {
    this.props.history.push(`/${screen}`)
  }

  gotoDashboard = (show) => {
    localStorage.setItem('recordType', show);
    this.navigateTo('dashboard');
  }

  render() {
    const {
      logoutUser
    } = this.props

    const isLoggedIn = localStorage.getItem('user_LoggedIn');
    const role = localStorage.getItem('role');
    return (
      <div>
      <Container className="HomePage" fluid>
        <Page loader={"bar"} color={"#A9A9A9"} size={6}>
        </Page>
          { isLoggedIn?
              <Row className="dashboard">
                    <Col xs={12} md={3} className="sideBar">
                      <header>
                        {/* <h4>UGANDA POLICE</h4> */}
                        <div className="logo">
                          <a href="/dashboard" className="simple-text logo-mini">
                            <div className="logo-img">
                              <img src={avatar} alt="logo_image" />
                            </div>
                          </a>
                          <a href="/dashboard" className="simple-text logo-normal">
                            <p>{window.localStorage.getItem('name')}</p>
                            <small>{window.localStorage.getItem('role')}</small>
                          </a>
                        </div>
                        <div className='logout'>
                          <Button variant="secondary" size="sm"  onClick={logoutUser}>
                            Logout
                          </Button>{' '}
                        </div>
                      </header>
                      {this.state.msg !== "Select the range"?<div
                        className={
                          "text-left mb-0 pt-3 pl-3 bold " +
                          (this.state.msg !== "Select the range" ? "text-danger" : "")
                        }
                      >
                        <strong>{this.state.msg}</strong>
                      </div>:<></>}

                      <Form inline>
                        <FormGroup controlId="formInlineName">
                          <FormControl type="text" placeholder="Case Quick Search" style={{width: '233px',marginRight: '2px'}}/>
                        </FormGroup>{' '}
                        <Button type="submit">Search</Button>
                      </Form>
                      <br />
                      {
                        role==='CRO Officer'?
                      <ListGroup style={{textAlign:'left'}}>
                        <ListGroupItem onClick={()=>this.gotoDashboard('temp')}>Update Request<Badge>102</Badge></ListGroupItem>
                        <ListGroupItem onClick={()=>this.gotoDashboard('temp')}>Temporary Records <Badge>5200</Badge></ListGroupItem>
                        <ListGroupItem onClick={()=>this.gotoDashboard('perm')}>Permanent Records <Badge>300</Badge></ListGroupItem>
                      </ListGroup>:<></>
                      }
                      {
                        role==='Admin'?
                      <ListGroup style={{textAlign:'left'}}>
                        <ListGroupItem>Notification <Badge>1</Badge></ListGroupItem>
                        <ListGroupItem>Notification <Badge>2</Badge></ListGroupItem>
                      </ListGroup>:<></>
                      }
                      
                      {
                        role==='Admin'?
                        <>
                          <br />
                          <WildcardSearch />
                          <div className='actionBtn'>
                        {/* <Button variant="secondary" size="sm" onClick={logoutUser}>
                            Logout
                        </Button>{' '} */}
                        {/* <Button variant="warning" size="sm">
                          clear
                        </Button> {' '}        */}
                        <Button variant="primary" size="sm">Search </Button>
                    </div>
                    
                        </>:<></>
                      }
                      <div className='actionBtn'>
                        {/* <Button variant="secondary" size="sm" onClick={logoutUser}>
                            Logout
                        </Button>{' '} */}
                        {/* <Button variant="warning" size="sm">
                          clear
                        </Button> {' '}       
                        <Button variant="primary" size="sm" onClick={() => this.navigateTo('dashboard')} disabled={false}> </Button> */}
                    </div>
                    </Col>
                    <Col xs={12} md={9} className="mainSection">
                      <header>
                        <img src={logo} alt='logo'/>
                        <h4 style={{marginBottom:'0'}}>UGANDA POLICE FORCE</h4>
                        {/* <h4 style={{marginBottom:'0'}}>Directorate of Forensic Services</h4> */}
                        <label>eCRO System</label>
                      </header>
                      <main style={{flexFlow:'column'}}>
                        <ButtonToolbar style={{padding:'10px 10px', width:'900%'}}>
                          <Button bsStyle="danger" bsSize="large" style={{background:'#22214e',border:'none'}}
                            onClick={()=>this.gotoDashboard('temp')}>
                            <Badge>300</Badge> Capital Cases
                          </Button>
                          <Button bsStyle="success" bsSize="large" style={{background:'#f6a35c',border:'none'}}
                            onClick={()=>this.gotoDashboard('perm')}>
                            <Badge>5200</Badge> Minor Offences
                          </Button>
                          <ButtonToolbar style={{marginLeft: '1rem',marginTop: '1rem'}}>
                            <ToggleButtonGroup type="radio" name="options" defaultValue={3}>
                              <ToggleButton value={1}>WTD</ToggleButton>
                              <ToggleButton value={2}>MTD</ToggleButton>
                              <ToggleButton value={3}>YTD</ToggleButton>
                              <ToggleButton value={4}>LY</ToggleButton>
                            </ToggleButtonGroup>
                          </ButtonToolbar>
                          <div style={{position:'relative',width:'300px'}}>
                            <Selection
                              yearFrom={this.state.yearFrom}
                              yearTo={this.state.yearTo}
                              onChangeYear={this.handleChangeYear}
                              onSubmit={this.handleSubmit}
                            />
                          </div>
                        </ButtonToolbar>
                        <div className="Kontainer mb-5 pb-3">
                          <Dboard
                            userConfig={this.state.userConfig}
                            charts={this.state.charts}
                          />
                        </div>
                      </main>
                    </Col>
                </Row>:
              <div></div>
          }
      </Container>
      </div>
    )
  }
}


const mapStateToProps = ({ user, records }) => {
	return {
    user,
    records
	};
};

export default connect(
	mapStateToProps,
	{ getUserRecords, getSelectedRecord, updateSearchField, logoutUser }
)(HomePage);