const data = {
    capitalOffence: {
      2010: 190,
      2011: 193,
      2012: 197,
      2013: 179,
      2014: 201,
      2015: 199,
      2016: 201,
      2017: 198,
      2018: 175,
      2019: 184,
      2020: 173
    },
    minorCases: {
      2010: 146,
      2011: 401,
      2012: 102,
      2013: 391,
      2014: 340,
      2015: 130,
      2016: 402,
      2017: 316,
      2018: 259,
      2019: 233,
      2020: 160
    },
    pcoke: {
      2010: 102,
      2011: 158,
      2012: 166,
      2013: 207,
      2014: 223,
      2015: 197,
      2016: 162,
      2017: 143,
      2018: 129,
      2019: 137,
      2020: 146
    },
    ngas: {
      2010: 639,
      2011: 691,
      2012: 648,
      2013: 101,
      2014: 260,
      2015: 142,
      2016: 591,
      2017: 290,
      2018: 91,
      2019: 189,
      2020: 895
    },
    ogas: {
      2010: 639,
      2011: 691,
      2012: 348,
      2013: 101,
      2014: 760,
      2015: 142,
      2016: 491,
      2017: 690,
      2018: 91,
      2019: 189,
      2020: 495
    },
    terrorism: {
      2010: 230,
      2011: 146,
      2012: 156,
      2013: 253,
      2014: 132,
      2015: 177,
      2016: 454,
      2017: 170,
      2018: 132,
      2019: 113,
      2020: 266        
    },
    nuclear: {
      2010: 125,
      2011: 364,
      2012: 234,
      2013: 527,
      2014: 387,
      2015: 218,
      2016: 426,
      2017: 207,
      2018: 854,
      2019: 66,
      2020: 205
    },
    chydroelectric: {
      2010: 216,
      2011: 331,
      2012: 204,
      2013: 417,
      2014: 322,
      2015: 246,
      2016: 212,
      2017: 230,
      2018: 445,
      2019: 204,
      2020: 355
    },
    wind: {
      2010: 627,
      2011: 353,
      2012: 185,
      2013: 144,
      2014: 171,
      2015: 260,
      2016: 449,
      2017: 361,
      2018: 386,
      2019: 453,
      2020: 120
    },
    solar: {
      2010: 543,
      2011: 556,
      2012: 533,
      2013: 577,
      2014: 550,
      2015: 509,
      2016: 609,
      2017: 865,
      2018: 888,
      2019: 1211,
      2020: 1818,
    },
    geothermal: {
      2010: 340,
      2011: 490,
      2012: 423,
      2013: 811,
      2014: 692,
      2015: 567,
      2016: 148,
      2017: 141,
      2018: 150,
      2019: 152,
      2020: 137
    },
    biomass: {
      2010: 249,
      2011: 708,
      2012: 342,
      2013: 517,
      2014: 278,
      2015: 262,
      2016: 509,
      2017: 533,
      2018: 513,
      2019: 590,
      2020: 573
    },
    wood: {
      2010: 35199,
      2011: 38663,
      2012: 37529,
      2013: 38117,
      2014: 38857,
      2015: 38762,
      2016: 39014,
      2017: 37299,
      2018: 36050,
      2019: 37172,
      2020: 37449
    },
    otherbio: {
      2010: 14549,
      2011: 15044,
      2012: 15811,
      2013: 15421,
      2014: 15419,
      2015: 16097,
      2016: 16525,
      2017: 17733,
      2018: 18440,
      2019: 18918,
      2020: 19222
    }
  };
  
  export default data;
  